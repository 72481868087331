// Generated by Framer (f7d95e4)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, Link, SmartComponentScopedContainer, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
import { Icon as Feather } from "https://framerusercontent.com/modules/f0DboytQenYh21kfme7W/zb1zVBMZJKgPMiedOi0y/Feather.js";
const FeatherFonts = getFonts(Feather);

const enabledGestures = {vfB3WVE4P: {hover: true}};

const serializationHash = "framer-P357q"

const variantClassNames = {vfB3WVE4P: "framer-v-1f9vn78"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "vfB3WVE4P", enabledGestures, ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Link href={"https://athosdark2.framer.website"} motionChild nodeId={"vfB3WVE4P"} openInNewTab scopeId={"tPQfgQiwa"}><motion.a {...restProps} {...gestureHandlers} className={`${cx(scopingClassNames, "framer-1f9vn78", className, classNames)} framer-qfax56`} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"vfB3WVE4P"} ref={refBinding} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-f0989dd8-6b42-4981-882e-6f9fe7f602ce, rgb(234, 232, 225))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 32, borderBottomRightRadius: 32, borderTopLeftRadius: 32, borderTopRightRadius: 32, ...style}} variants={{"vfB3WVE4P-hover": {"--border-color": "rgb(61, 61, 61)", backgroundColor: "var(--token-a378a457-09c9-4e21-8971-31a4dc6ae8ea, rgb(17, 16, 17))"}}} {...addPropertyOverrides({"vfB3WVE4P-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><SmartComponentScopedContainer className={"framer-ar9vx6-container"} isAuthoredByUser isModuleExternal layoutDependency={layoutDependency} layoutId={"OqzusT69l-container"} nodeId={"OqzusT69l"} rendersWithMotion scopeId={"tPQfgQiwa"}><Feather color={"var(--token-77abf34c-bfd0-46e4-bdc5-396472ca5ca6, rgba(17, 16, 17, 0.6))"} height={"100%"} iconSearch={"Home"} iconSelection={"moon"} id={"OqzusT69l"} layoutId={"OqzusT69l"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"} {...addPropertyOverrides({"vfB3WVE4P-hover": {color: "var(--token-c3dd294d-4924-4bd7-80f1-2cfdc6e0e574, rgb(249, 247, 246))"}}, baseVariant, gestureVariant)}/></SmartComponentScopedContainer></ComponentViewportProvider></motion.a></Link></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-P357q.framer-qfax56, .framer-P357q .framer-qfax56 { display: block; }", ".framer-P357q.framer-1f9vn78 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 5px; height: min-content; justify-content: center; overflow: hidden; padding: 12px; position: relative; text-decoration: none; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-P357q .framer-ar9vx6-container { flex: none; height: 24px; position: relative; width: 24px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-P357q.framer-1f9vn78 { gap: 0px; } .framer-P357q.framer-1f9vn78 > * { margin: 0px; margin-left: calc(5px / 2); margin-right: calc(5px / 2); } .framer-P357q.framer-1f9vn78 > :first-child { margin-left: 0px; } .framer-P357q.framer-1f9vn78 > :last-child { margin-right: 0px; } }", ".framer-P357q[data-border=\"true\"]::after, .framer-P357q [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 48
 * @framerIntrinsicWidth 48
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"dCsiCizpM":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramertPQfgQiwa: React.ComponentType<Props> = withCSS(Component, css, "framer-P357q") as typeof Component;
export default FramertPQfgQiwa;

FramertPQfgQiwa.displayName = "DarkMode 2";

FramertPQfgQiwa.defaultProps = {height: 48, width: 48};

addFonts(FramertPQfgQiwa, [{explicitInter: true, fonts: []}, ...FeatherFonts], {supportsExplicitInterCodegen: true})